@import "../../styles/mixins";
.main-wrapper {
  @include md {
    padding-left: 280px;
  }
  .main-wrapper-inside {
    padding-top: 60px;
    flex: 1 1 0%;
    overflow: hidden;
    > .scroll-bar {
      overflow-y: scroll;
      height: 100%;
    }
  }
}
