.chat-header {
  top: 0px;
  left: 0px;
  border-bottom: 1px solid #8a8a8a;
  h4 {
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    padding-left: 10px;
    padding-right: 20px;
    overflow: hidden;
    flex: 1 0;
  }
  button {
    background: transparent;
  }

  .view-prompts {
    display: flex;
    align-items: center;
    white-space: pre;
    margin-left: 8px;
    color: #043873;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    margin-right: 8px;
    text-decoration: none;
  }

  @media (max-width: 768px) {
    .view-prompts {
      display: none;
    }
  }
}
