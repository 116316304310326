@use './variables' as *;

.breadcrumb-custom {
    margin-top: 10px;

    h2 {
        font-family: "Poppins", sans-serif;
        font-size: 14px;
        font-weight: 400;
        color: #003f2d;
        display: flex;
        margin-bottom: 0px;
        align-items: center;
        text-transform: uppercase;

        i {
            font-size: 28px;
            margin-top: -5px;
        }

        span {
            display: inline-block;
            margin-right: 10px;
            margin-left: 10px;
        }

        .current-page {
            margin-left: 0px;
            color: $primary-bg;
        }
    }
}