@use "./mixins" as *;

.form-label {
  font-size: rem(14);
  font-weight: 600;
  color: #475569;
  margin-bottom: rem(4);
  display: block;
}

.form-control {
  &:focus {
    box-shadow: 0 0 0 0.125rem rgba(199, 210, 254, 1);
    -webkit-box-shadow: 0 0 0 0.125rem rgba(199, 210, 254, 1);
    -o-box-shadow: 0 0 0 0.125rem rgba(199, 210, 254, 1);
    -moz-box-shadow: 0 0 0 0.125rem rgba(199, 210, 254, 1);
  }

  &-sm {
    height: rem(36);
    min-height: rem(36);
    font-size: rem(14);
    line-height: rem(20);
  }
}

.was-validated .form-control:valid,
.form-control.is-valid,
.was-validated .form-control:invalid,
.form-control.is-invalid {
  background-image: none !important;
}

.form-select {
  background-repeat: no-repeat;
  background-position: calc(100% - 19px) 50%;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTEiIGhlaWdodD0iNiIgdmlld0JveD0iMCAwIDExIDYiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMC41NDI4OTMgMC4yOTI4OTNDMC45MzM0MTcgLTAuMDk3NjMxIDEuNTY2NTggLTAuMDk3NjMxIDEuOTU3MTEgMC4yOTI4OTNMNS4yNSAzLjU4NTc5TDguNTQyODkgMC4yOTI4OTNDOC45MzM0MiAtMC4wOTc2MzExIDkuNTY2NTggLTAuMDk3NjMxMSA5Ljk1NzExIDAuMjkyODkzQzEwLjM0NzYgMC42ODM0MTcgMTAuMzQ3NiAxLjMxNjU4IDkuOTU3MTEgMS43MDcxMUw1Ljk1NzExIDUuNzA3MTFDNS41NjY1OCA2LjA5NzYzIDQuOTMzNDIgNi4wOTc2MyA0LjU0Mjg5IDUuNzA3MTFMMC41NDI4OTMgMS43MDcxMUMwLjE1MjM2OSAxLjMxNjU4IDAuMTUyMzY5IDAuNjgzNDE4IDAuNTQyODkzIDAuMjkyODkzWiIgZmlsbD0iIzk0QTNCOCIvPgo8L3N2Zz4K);
  background-size: 10px 6px;

  option {
    padding: 8px 16px 8px 16px !important;
    @include border-radius(8px);
    color: #0f172a;
    appearance: none;
    -webkit-appearance: none;

    &:hover {
      background-color: #f1f5f9 !important;
    }

    &:checked {
      background-color: #e0e7ff;
    }
  }
}

.location .input-group .form-control {
  padding-left: 12px !important;
}

// contacted
.contacted {
  display: flex;
  margin-bottom: rem(28);

  @include md {
    flex-direction: column;
    justify-content: space-between;
  }

  font-size: rem(14);

  .form-check {
    font-size: rem(14);

    @include md {
      justify-content: space-between;
    }

    .form-check-label {
      font-size: rem(14) !important;
    }

    .form-check-input {
      width: rem(14) !important;
      height: rem(14) !important;
      margin-top: rem(3);
    }
  }
}

.input-group-control {
  margin-bottom: rem(28);
}

.g-recaptcha {
  margin-bottom: rem(28);

  @include sm {
    transform: scale(0.72);
    transform-origin: 0 0;
    margin-bottom: rem(10);
  }
}

.input-group {
  .form-control {
    border-radius: rem(8) !important;
    padding-left: rem(38) !important;
  }

  .form-select {
    border-radius: rem(8);
    padding-left: rem(38);
  }

  .input-icon {
    position: absolute;
    left: 12px;
    top: 50%;
    z-index: 6;
    transform: translateY(-50%);
    color: #64748b;
    width: rem(16);

    svg {
      width: rem(16);
      height: auto;
    }
  }
}

#shared-inputs {
  .search__dropdown {
    width: rem(200) !important;
  }
}

//customize inputs and dropdown for the calculator
.calculator__group {
  .calculator__group_label {
    h6 {
      font-weight: 700;
      font-size: rem(14);
      line-height: rem(28);
      color: #313144;
    }

    p {
      font-weight: 600;
      font-size: rem(12);
      line-height: rem(20);
      color: #333333;
    }
  }
}

.calculator__wrap {
  padding: rem(18) rem(56);
  border-radius: rem(8);
  background: #ffffff;
  box-shadow: 0px 20px 25px rgb(31 41 55 / 10%),
    0px 10px 10px rgb(31 41 55 / 4%);
  margin-bottom: rem(20);
}

.calculator__heading {
  font-style: normal;
  color: #313144;
  font-size: rem(16);
  margin-bottom: 2rem;
  text-align: center;
  font-weight: 800;
}

.calculator__result_wrap {
  background: #ffe492;
  border-radius: rem(8);
  padding: rem(24) rem(56);
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  color: #212121;
  align-items: center;
}

.calculator__result_label {
  line-height: rem(32);
}

.calculator__result {
  line-height: rem(32);
}

.calculator__group_dropdown {
  .input-group {
    width: 29%;

    .form-select {
      padding-left: rem(13);
    }
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.sso-btn {
  letter-spacing: 0 !important;
  color: black;
  font-size: rem(14) !important;
  font-weight: 400 !important;

  &:hover {
    color: white;
  }

  svg {
    width: rem(20);
    height: rem(20);
  }
}

.keyword-input input {
  border-bottom: none !important;
}

textarea {
  resize: none;
}