.quote-status {
    border-radius: 6px;
    padding: 2px 9px;
    font-weight: 500;
    font-size: 13px;
    white-space: nowrap;
    &.status-quoted {
        background-color: #fbe4d1;
        color: #b4611e;
    }
    &.status-draft {
        background-color: #efeabd;
        color: #7c7537;
    }
    &.status-approved {
        background-color: #c7fae4;
        color: #3c8d6a;
    }
    &.status-cancel {
        background-color: #880808;
        color: #fff;
    }
    &.status-revision {
        background-color: #c7f3fa;
        color: #1f7682;
    }
    &.status-deleted {
        background-color: #fec7c7;
        color: #883c3c;
    }
    &.status-rejected {
        background-color: #f6e1da;
        color: #f22b2b;
    }
    &.status-expired {
        background-color: #880808;
        color: #fff;
    }
}
