@use "../../styles/variables" as *;
@use "../../styles/mixins" as *;

.container__alert {
    max-width: 415px;

    min-height: 66px;
    position: fixed !important;
    right: 10px;
    bottom: 10px !important;
    z-index: 2000;
    border-radius: 8px !important;
    border: none !important;

    @include sm {
        max-width: 100%;
    }

    .icon-type {
        padding: 5px;
        width: 30px;
        height: 30px;
        margin-right: 10px;
    }
}