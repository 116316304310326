@import "../../styles/mixins.scss";

.examples-page {
  h3 {
    padding-top: 4px;
    padding-bottom: 12px;
  }

  .container-fluid {
    list-style: none;
    display: flex !important;
    gap: 8px;
    padding-inline: 18px;
    align-items: unset;
    @include lg {
      padding-inline: 72px !important;
      align-items: center !important;
    }
    .card-container {
      text-align: center;
      margin-bottom: 20px;

      padding: 0px 14px !important;
      @include lg {
        padding: 0px 12px !important;
      }
    }
    .example-prompt {
      text-align: center !important;
      color: #2e3238;
      height: auto !important;
      background: transparent;
      text-align: left;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #c6cad1;
      border-radius: 0.5rem;
      margin-bottom: 8px;
      font-weight: 400;
      padding: 8px 18px;
      @include xxl {
        height: 80px !important;
      }
      @include lg {
        padding: 12px 20px !important;
      }

      cursor: pointer;
      transition: all 0.2s ease-in-out;
      -o-transition: all 0.2s ease-in-out;
      -moz-transition: all 0.2s ease-in-out;
      -webkit-transition: all 0.2s ease-in-out;

      &:hover {
        -webkit-box-shadow: 0px 0px 9px 1px rgba(0, 0, 0, 0.2);
        -moz-box-shadow: 0px 0px 9px 1px rgba(0, 0, 0, 0.2);
        box-shadow: 0px 0px 9px 1px rgba(0, 0, 0, 0.2);
      }

      h3 {
        margin-top: 12px;
        margin-bottom: 8px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }

      p {
        overflow: hidden;
        display: -webkit-box;
        // -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        font-weight: 400;
      }
    }
  }
}

.conversation-wrapper {
  height: calc(100% - 60px);

  .conversation {
    padding: 24px 12px;

    .conversation-inner {
      display: flex;
      flex-direction: column;
      //align-items: center;
      margin: auto;
      max-width: 740px;
      @include lg {
        //flex-wrap: wrap !important;
        flex-direction: row !important;
      }
    }

    @include md {
      padding: 24px 32px;
    }

    .avatar {
      width: 38px;
      height: 38px;
      border-radius: 6px;
      margin-right: 24px;
      background: #727d8d;
      font-size: 14px;
      line-height: 13px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      min-width: 38px;
      .avatar-container {
        display: flex;
        flex-direction: column;
      }
    }
    .logo {
      background-color: black !important;
      color: white !important;
    }

    .message {
      //flex-grow: 1;
      align-items: center;
      max-width: 560px;
      p {
        white-space: pre-line;
      }
    }

    .feedback {
      width: 40px;
      display: flex;
      justify-content: start;
      margin-left: 9%;
      margin-top: 12px !important;
      flex-grow: 1;
      @include lg {
        display: flex;
        justify-content: flex-end !important;
        margin-left: 6rem;
        margin-top: 0px !important;
      }
      @include xs {
        display: flex;
        margin-left: 4rem;
        align-items: center !important;
        text-align: center !important;
      }
    }
  }
}

.wmde-markdown {
  ul {
    li {
      list-style: disc;
    }
  }
}
.microphone {
  position: absolute;
  top: 50%;
  right: -52px;
  height: 46px;
  width: 46px;
  border: none;
  border-radius: 7px;
  overflow: hidden;
  svg {
    path {
      opacity: 0;
      &:first-child {
        opacity: 1;
        fill: var(--primary);
      }
    }
  }
  img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 3px;
    left: 0px;
    transform: scale(1.25);
    display: none;
  }
  &.active {
    background-color: var(--primary);
    img {
      display: block;
    }
    svg {
      display: none;
      path {
        &:nth-of-type(1) {
          fill: #fff;
        }
      }
    }
  }
}
.privacy-links {
  margin: 0;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  bottom: 0;
  p {
    white-space: pre;
  }
}
@media (max-width: 768px) {
  .small-screen-btn {
    font-size: 12px;
    padding: 5px 10px;
  }
}
