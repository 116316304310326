@use "../../styles/variables" as *;
@use "../../styles/mixins" as *;

.container__Prompts {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(auto, 1fr));
  gap: 16px;
  overflow: scroll;
  overflow-x: hidden;
  padding-left: 10px;
  padding-right: 10px;
  max-height: 380px;
  margin-bottom: 20px;
  @include sm {
    grid-template-columns: repeat(auto-fill, minmax(310px, 1fr));
  }
}

.model {
  background-color: var(--neutral-100);
}

.view-prompts {
  border: 1px solid #ccc;
  padding: 16px;
  border-radius: 8px;
  cursor: pointer;
  text-align: center;
}

.view-prompts:hover {
  background-color: #f1f1f1;
}

.search-bar {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 4px;
  box-shadow: 0px 3.207031011581421px 40.8896484375px 0px #0000001a;
  z-index: 1;
  margin-left: 10px;
  margin-right: 18px;
  margin-top: 10px;
  border: none;
}

.search-bar-input[type="text"] {
  flex-grow: 1;
  padding: 8px;
  border: none;
  font-size: 14px;
  background-color: transparent;
  outline: none;
}

search-bar-input[type="text"]::placeholder {
  color: #000000;
}

.search-icon {
  margin-left: 8px;
  margin-right: 10px;
  color: #333333;
}
.modal-bg-grey {
  background-color: #f9f9f9;
  border-radius: var(--bs-modal-border-radius);
}
