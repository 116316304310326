@import "styles/main.scss";
.auth-left-panel {
  p {
    font-size: rem(14) !important;
    color: var(--gray-500);
  }

  .form-check-label {
    font-size: rem(14);
    line-height: rem(20);
    cursor: pointer;
    margin-left: rem(8);
    font-weight: 400;
    font-size: #212121;
  }
  .sso-btn {
    letter-spacing: 0 !important;
    color: black;
    font-size: rem(14) !important;
    font-weight: 400 !important;
    display: flex;
    gap: 12px;
    justify-content: center;
    align-items: center;
    &:hover {
      color: white;
    }

    img {
      width: rem(20);
      height: rem(20);
    }
  }
  .or {
    color: #7a7a7a;
    font-size: 20px;
  }
  .line {
    width: 100%;
    height: 1px;
    background: #e0e0e0;
  }

  .account-text {
    p {
      font-size: rem(16) !important;
      color: #000000;
    }
  }
}
.bullet-points , li {
    list-style: disc !important;
    margin-bottom: 8px !important;
}
.sub-heading-auth {
  p {
    color: #646464;
    font-weight: 500;
  }
  .mobile-icon {
    width: 200px;
    display: none; /* Initially hide the icon */
    @media (max-width: 768px) {
      display: block;
      margin: 0 auto;
    }
  }

}
.button-padding{
  padding-top: 6px;
}
.faqs-heading{
  font-size: 24px !important;
  margin: 42px 24px 24px!important;
  @include md {
    margin: 52px 24px 24px!important;
  }
}
