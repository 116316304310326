@use "sass:string";
@use "./mixins" as *;

html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  border: 0;
  outline: 0;
  vertical-align: baseline;
  background: transparent;
  font-family: $primary-font;
}
a {
  text-decoration: none;
  outline-offset: unset;
}

a:-webkit-any-link:focus-visible {
  outline-offset: unset;
}
html {
  font-size: 16px;
}
body {
  color: $body-text;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  position: relative;
  background: #ffffff;
  display: flex;
  min-height: 100%;
  height: 100%;
  flex-direction: column;
}
html,
body,
#root {
  height: 100% !important;
}
ul,
li {
  list-style: none;
  margin-left: 0px;
  padding-left: 0px;
}

.header-item {
  display: flex;
  align-items: center;
}

/* Width */

@for $i from 1 through 100 {
  $postfix: $i * 1;

  .width-px-#{$postfix} {
    width: rem($i * 1);
  }
}

//min width
@for $i from 1 through 100 {
  $postfix: $i * 1;

  .min-width-px-#{$postfix} {
    min-width: rem($i * 1);
  }
}

/* Float */
.float {
  &-right {
    float: right;
  }

  &-left {
    float: left;
  }
}

/*Height*/
@for $i from 1 through 1000 {
  $postfix: $i * 1;

  .height-px-#{$postfix} {
    height: rem($i * 1);
  }
}

//Min Height
@for $i from 1 through 100 {
  $postfix: $i * 1;

  .min-height-px-#{$postfix} {
    min-height: rem($i * 1);
  }
}

/* Padding top Classes */
@for $i from 1 through 100 {
  $postfix: $i * 1;

  .pt-px-#{$postfix} {
    padding-top: rem($i * 1);
  }
}

/* Padding Bottom Classes */

@for $i from 1 through 100 {
  $postfix: $i * 1;

  .pb-px-#{$postfix} {
    padding-bottom: rem($i * 1);
  }
}

//padding :right
@for $i from 1 through 100 {
  $postfix: $i * 1;

  .pr-px-#{$postfix} {
    padding-right: rem($i * 1);
  }
}

//padding :left
@for $i from 1 through 100 {
  $postfix: $i * 1;

  .pl-px-#{$postfix} {
    padding-left: rem($i * 1);
  }
}

/* margin bottom */

@for $i from 1 through 100 {
  $postfix: $i * 1;

  .mb-px-#{$postfix} {
    margin-bottom: rem($i * 1);
  }
}

/* margin bottom */
@for $i from 1 through 100 {
  $postfix: $i * 1;

  .mb-px-#{$postfix} {
    margin-bottom: rem($i * 1);
  }
}

/* margin top */

@for $i from 1 through 100 {
  $postfix: $i * 1;

  .mt-px-#{$postfix} {
    margin-top: rem($i * 1);
  }
}

@for $i from 1 through 100 {
  $postfix: $i * 1;

  .mb-px-n-#{$postfix} {
    margin-bottom: rem(-$i * 1);
  }
}

/* margin left */

@for $i from 1 through 100 {
  $postfix: $i * 1;

  .ml-px-#{$postfix} {
    margin-left: rem($i * 1);
  }
}

/* margin right */

@for $i from 1 through 100 {
  $postfix: $i * 1;

  .mr-px-#{$postfix} {
    margin-right: rem($i * 1);
  }
}

.b {
  &t {
    &-0 {
      border-top: 0px none;
    }
  }
}

/* Section default view */
section {
  padding: 80px 0px;
  @include md {
    padding: 60px 0px;
  }
}

.seperator-bb {
  border-bottom: 4px solid #d9dddc;
}

.seperator-bt {
  border-top: 4px solid #d9dddc;
}

.flex-1 {
  flex: 1;
}

.flex-0 {
  flex: 0;
}

label {
  font-weight: 600;
}

.text-gray-700 {
  color: var(--gray-700);
}

.text-gray-500 {
  color: var(--gray-500);
}

.text-gray-300 {
  color: var(--gray-300);
}

.text-gray-100 {
  color: var(--gray-100);
}
.text-cyan {
  color: var(--cyan);
}
.bg-gray-700 {
  background: var(--gray-700);
}

.bg-gray-500 {
  background: var(--gray-500);
}

.bg-gray-300 {
  background: var(--gray-300);
}

.bg-gray-100 {
  background: var(--gray-100);
}

.bg-white {
  background-color: var(--white);
}

.response-bg {
  background-color: var(--response-bg);
}

.border-color-800 {
  border: 1px solid var(--gray-800);
}
::-webkit-scrollbar {
  width: rem(8);
}
::-webkit-scrollbar-track {
  background: #e8e8e8;
}
/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: rem(100);
  background: #7a7a7a;
}
.error-msg {
  color: #f93939 !important;
  font-size: 12px;
}
.container__updated {
  position: absolute;
  right: rem(19);
  top: rem(14);
  background: $secondary-bg;
  border-radius: rem(8);
  padding: rem(10);
  text-align: center;

  .updated__text {
    font-weight: 600;
    font-size: rem(12);
    color: $primary-text;
    margin-bottom: 0 !important;
  }
}

.area__dropdown {
  select {
    padding-left: 12px !important;
  }
}
.beds__dropdown {
  select {
    padding-left: 12px !important;
  }
}
.baths__dropdown {
  select {
    padding-left: 12px !important;
  }
}

.zoom_effect {
  img {
    transition: transform 0.5s;
    &:hover {
      -ms-transform: scale(1.1); /* IE 9 */
      -webkit-transform: scale(1.1); /* Safari 3-8 */
      transform: scale(1.1);
    }
  }
}
.form-check {
  min-height: rem(0) !important;
  margin-bottom: rem(0) !important;
  padding-left: rem(0) !important;
  margin-right: rem(12);
  .form-check-label {
    margin-right: rem(4) !important;
    font-weight: 400;
    font-size: rem(17);
    color: #212121;
  }
  .form-check-input {
    float: none !important;
    margin-left: 12px !important;
    border: 1px solid #d5d5d5 !important;
    border-radius: 50px !important;
  }
}
.contact_update {
  font-size: rem(14);
}

.tags-main {
  padding: rem(8) rem(4) !important;
  border-radius: rem(8) !important;
}
.input-wrapper {
  margin-top: rem(0) !important;
}
.tag-container {
  border-radius: rem(100) !important;
  padding: rem(4) rem(8) !important;
  filter: drop-shadow(2px 2px 4px rgba(26, 15, 15, 0.15));
}

// for desktop
h1,
.h1 h2,
.h2 h3,
.h3 h4,
.h4 h5,
.h5 h6,
.h6 {
  text-transform: capitalize;
}
// for mobile devices
@include md {
  h1,
  .h1 {
    font-size: rem(36);
    line-height: rem(40);
  }
  h2,
  .h2 {
    font-size: rem(30);
    line-height: rem(36);
  }
  h3,
  .h3 {
    font-size: rem(24);
    line-height: rem(32);
  }
  h4,
  .h4 {
    font-size: rem(20);
    line-height: rem(28);
  }
}
.desktop-only {
  display: none;
}
@include desktop-only {
  .desktop-only {
    display: block;
  }
}
.mobile-only {
  display: none;
}
@include mobile-only {
  .mobile-only {
    display: block;
  }
}

.scroll-bar {
  max-height: 100%;
  height: 100%;
  /* width */
  &::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #d9d9d9;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #939393;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #686868;
  }
}
