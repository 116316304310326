@use "./mixins" as *;

.btn {
  text-transform: capitalize;
  min-width: rem(20);
  max-width: 100%;
  height: rem(44);
  border-radius: rem(8);
  position: relative;
  font-weight: 600;

  &-sm {
    height: rem(36);
    line-height: rem(18);
  }

  &-icon {
    width: 44px;
    height: 44px;
    padding: 0;
    text-align: center;

    svg {
      margin: auto;
      width: 24px;
      display: block;
    }

    &.btn-sm {
      padding: 0;
      width: rem(36);
      height: rem(36);
    }
  }
}

.btn-calc-height {
  height: 4.6rem;
}

.btn-outline-primary {
  background-color: transparent;
  border-color: $primary-bg;
}

.btn-outline-white {
  color: $primary-text;
}

.btn-gray-border {
  width: 100%;
  background: #ffffff;
  border: 1px solid #d1d5db;
  box-shadow: 0px 1px 2px rgb(0 0 0 / 5%);
  border-radius: 6px;
  padding: rem(9) rem(16);
  height: 100%;
  font-size: rem(14);
}

.btn-gray-border-time {
  font-size: rem(10);
  font-weight: 500;
}

.btn-gray-border-active {
  background: $primary-bg;
  color: white;
  width: 100%;
  border: rem(1) solid #d1d5db;
  box-shadow: 0 rem(1) rem(2) rgb(0 0 0 / 5%);
  border-radius: rem(6);
  padding: rem(9) rem(16);
  height: 100%;
}
.navbar-toggler {
  padding: unset;
  font-size: var(--bs-navbar-toggler-font-size);
  line-height: 1;
  color: var(--bs-navbar-color);
  background-color: transparent;
  border: unset;
  border-radius: unset;
  transition: var(--bs-navbar-toggler-transition);
}
