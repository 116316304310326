.toasters {
    .alert-info {
        background-color: #EFF6FF !important;

        .title {
            color: #1E40AF !important;
        }

        svg {
            color: #60A5FA,
        }

        span {
            color: #1E40AF !important;
            font-size: 14px;
            font-weight: 400;
        }
    }

    .alert-danger {
        background-color: #FEF2F2 !important;

        .title {
            color: #991B1B !important;
        }

        svg {
            color: #F87171,
        }

        span {
            color: #7C251D !important;
            font-size: 14px;
            font-weight: 400;
        }
    }

    .alert-success {
        background-color: #ECFDF5 !important;

        .title {
            color: #065F46 !important;
        }

        svg {
            color: #34D399,
        }

        span {
            color: #267351 !important;
            font-size: 14px;
            font-weight: 400;
        }
    }

    .alert-warning {
        background-color: #FFFBEB !important;

        .title {
            color: #92400E !important;
        }

        svg {
            color: #FBBF24,
        }

        span {
            color: #B45309 !important;
            font-size: 14px;
            font-weight: 400;
        }
    }
}