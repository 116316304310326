@use "./variables" as *;
.modal-dialog {
  margin-top: 5%;
  .modal-header {
    border-radius: unset;
  }
}

.btn-close {
  position: absolute;
  right: 14px;
  top: 14px;
  z-index: 99;
  width: 0.36rem !important;
  height: 0.36rem !important;
  font-size: 0.8rem;
  color: var(--text-gray-300);
  background-color: transparent;
  border: none;
  &:hover {
    background-color: transparent;
  }
}

/* Modal - Resume Previous Form */
.modal-custom-outer {
  max-width: 470px;

  .modal-custom {
    width: 100%;
    background-color: #fff;

    .modal-body {
      padding: 20px;
    }

    &_icon {
      display: block;
      width: 75px;
      margin: 10px auto 15px;

      img {
        width: 100%;
        display: block;
      }
    }

    &_title {
      text-align: center;
      font-size: 18px;
      color: #9f9f9f;
      margin-bottom: 25px;
    }

    .modal_btn {
      width: 100%;
      border-radius: 0px;
      margin-bottom: 16px;
      font-size: 18px;
      box-sizing: border-box;

      &:focus {
        box-shadow: none;
      }

      &--outline {
        margin-bottom: 0px;
        border: 3px solid #e0e0e0;
        color: $primary-bg;
        background: #fff;
      }
    }
  }
}

.modal.show {
  display: block;
}
.btn-modal {
  //max-width: 100%;
  width: 148px;
}
.modal-backdrop.show {
  opacity: var(--bs-backdrop-opacity);
}

.modal-backdrop {
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: rgb(0, 0, 0);
  --bs-backdrop-opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--bs-backdrop-bg);
}

.btn-close {
  background-image: none !important;
  &:hover {
    background-color: transparent !important;
  }
}
