@use "sass:math";
@use "./variables" as *;

// generic transform
@mixin transform($transforms) {
  -moz-transform: $transforms;
  -o-transform: $transforms;
  -ms-transform: $transforms;
  -webkit-transform: $transforms;
  transform: $transforms;
}

// rotate
@mixin rotate($deg) {
  @include transform(rotate(#{$deg}deg));
}

// scale
@mixin scale($scale) {
  @include transform(scale($scale));
}

// translate
@mixin translate($x, $y) {
  @include transform(translate($x, $y));
}

// skew
@mixin skew($x, $y) {
  @include transform(skew(#{$x}deg, #{$y}deg));
}

//transform origin
@mixin transform-origin($origin) {
  moz-transform-origin: $origin;
  -o-transform-origin: $origin;
  -ms-transform-origin: $origin;
  -webkit-transform-origin: $origin;
  transform-origin: $origin;
}

dev.usage {
  @include transform(translate(50%, 0));
}

@mixin prefixes($property, $value) {
  -webkit-#{$property}: $value;
  -moz-#{$property}: $value;
  -ms-#{$property}: $value;
  -o-#{$property}: $value;
}

div.usage {
  @include prefixes(property, value);
}

/* Border Radius */

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -ms-border-radius: $radius;
  -o-border-radius: $radius;
  border-radius: $radius;
}

div.usage {
  @include border-radius(10px);
}

/* Font Size */

@function rem($pixels, $context: 16) {
  @return (math.div($pixels, $context)) * 1rem;
}

div.usage {
  width: rem(400); // → 25rem
}

/* Transition */

@mixin transition($args) {
  -webkit-transition: $args;
  -moz-transition: $args;
  -ms-transition: $args;
  -o-transition: $args;
  transition: $args;
}

div.usage {
  @include transition(color 0.3s ease);
}

@mixin box-sizing() {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

@mixin vertical-align($position: relative) {
  position: $position;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

@mixin horizontal-align($position: relative) {
  position: $position;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

/* opacity */

@mixin opacity($opacity) {
  opacity: $opacity;
  $opacity-ie: $opacity * 100;
  filter: alpha(opacity=$opacity-ie); //IE8
}

div.usage {
  @include opacity(0.8);
}

/* Breakpoints */
$breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
);

@mixin xs {
  @media (min-width: map-get($breakpoints, "xs")) {
    @content;
  }
}

@mixin sm {
  @media (min-width: map-get($breakpoints, "sm")) {
    @content;
  }
}

@mixin md {
  @media (min-width: map-get($breakpoints, "md")) {
    @content;
  }
}

@mixin lg {
  @media (min-width: map-get($breakpoints, "lg")) {
    @content;
  }
}

@mixin xl {
  @media (min-width: map-get($breakpoints, "xl")) {
    @content;
  }
}

@mixin xxl {
  @media (min-width: map-get($breakpoints, "xxl")) {
    @content;
  }
}

@mixin desktop-only {
  @media only screen and (min-width: 768px) {
    @content;
  }
}

@mixin mobile-only {
  @media only screen and (max-width: 767.98px) {
    @content;
  }
}

@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }

  &:-moz-placeholder {
    @content;
  }

  &::-moz-placeholder {
    @content;
  }

  &:-ms-input-placeholder {
    @content;
  }
}

// Utility generator
// Used to generate utilities & print utilities
@mixin generate-utility($utility, $infix, $is-rfs-media-query: false) {
  $values: map-get($utility, values);

  // If the values are a list or string, convert it into a map
  @if type-of($values) == "string" or type-of(nth($values, 1)) != "list" {
    $values: zip($values, $values);
  }

  @each $key, $value in $values {
    $properties: map-get($utility, property);

    // Multiple properties are possible, for example with vertical or horizontal margins or paddings
    @if type-of($properties) == "string" {
      $properties: append((), $properties);
    }

    // Use custom class if present
    $property-class: if(
      map-has-key($utility, class),
      map-get($utility, class),
      nth($properties, 1)
    );
    $property-class: if($property-class ==null, "", $property-class);

    // State params to generate pseudo-classes
    $state: if(map-has-key($utility, state), map-get($utility, state), ());

    $infix: if(
      $property-class == "" and str-slice($infix, 1, 1) == "-",
      str-slice($infix, 2),
      $infix
    );

    // Don't prefix if value key is null (eg. with shadow class)
    $property-class-modifier: if(
      $key,
      if($property-class == "" and $infix == "", "", "-") + $key,
      ""
    );

    @if map-get($utility, rfs) {
      // Inside the media query
      @if $is-rfs-media-query {
        $val: rfs-value($value);

        // Do not render anything if fluid and non fluid values are the same
        $value: if($val ==rfs-fluid-value($value), null, $val);
      } @else {
        $value: rfs-fluid-value($value);
      }
    }

    $is-css-var: map-get($utility, css-var);
    $is-local-vars: map-get($utility, local-vars);
    $is-rtl: map-get($utility, rtl);

    @if $value !=null {
      @if $is-rtl ==false {
        /* rtl:begin:remove */
      }

      @if $is-css-var {
        .#{$property-class + $infix + $property-class-modifier} {
          --#{$variable-prefix}#{$property-class}: #{$value};
        }

        @each $pseudo in $state {
          .#{$property-class
            + $infix
            + $property-class-modifier
            }
            -#{$pseudo}:#{$pseudo} {
            --#{$variable-prefix}#{$property-class}: #{$value};
          }
        }
      } @else {
        .#{$property-class + $infix + $property-class-modifier} {
          @each $property in $properties {
            @if $is-local-vars {
              @each $local-var, $value in $is-local-vars {
                --#{$variable-prefix}#{$local-var}: #{$value};
              }
            }

            #{$property}: $value
              if($enable-important-utilities, !important, null);
          }
        }

        @each $pseudo in $state {
          .#{$property-class
            + $infix
            + $property-class-modifier
            }
            -#{$pseudo}:#{$pseudo} {
            @each $property in $properties {
              #{$property}: $value
                if($enable-important-utilities, !important, null);
            }
          }
        }
      }

      @if $is-rtl ==false {
        /* rtl:end:remove */
      }
    }
  }
}

@mixin spinner-animation {
  animation: spinner-animation 0.8s infinite linear;

  @keyframes spinner-animation {
    100% {
      transform: rotate(1turn);
    }
  }
}
