/* Fonts' Variables */
$primary-font: "Inter", sans-serif;

/* Colors' Variables */
$body-text: #212121;

$primary-text: #043873;
$primary-bg: #043873;
$primary-border: #043873;
$primary-inputBorderColor: #043873;

$secondary-text: #ffe492;
$secondary-bg: #ffe492;

$heading-text: $body-text;

$grey-bg: #f2f5f8;

$white: #ffffff;
$black: #000000;

$inputPlaceholderColor: #333333;
$inputColor: #333333;
$inputBgColor: #e8e8e8;

$form-check-input-bg: $white !important;
$form-check-input-border: 1px solid #333333 !important;

$form-check-input-checked-bg-color: #043873 !important;
$form-check-input-checked-border-color: #043873 !important;

$inputBorderColor: #e8e8e8;
$danger-inputBorderColor: #f93939;
$disabledInputColor: #eff2f7;

$colors: (
  primary: $primary-text,
  secondary: $secondary-text,
  success: #1ad598,
  success-shade: rgba(48, 204, 159, 0.1),
  success-text: #1ad598,
  warning-shade: rgba(255, 193, 7, 0.1),
  warning-text: #deaf1f,
  danger-shade: rgba(230, 125, 60, 0.1),
  danger-text: #f93939,
  info: #3abaf4,
  warning: #ffc107,
  danger: #f93939,
  light: #e3eaef,
  dark: #34395e,
  muted: #98a6ad,
  body: $body-text,
  font: #6c757d,
  fontdark: #34395e,
  // Form Control
  formcontrol_normal_background: #fdfdff,
  formcontrol_normal_border: #e4e6fc,
  formcontrol_focus_background: #fefeff,
  formcontrol_focus_border: $primary-bg,
  // Utilities
  section_title_line: $primary-bg,
);

@function color($key) {
  @return map-get($colors, $key);
}

@function color_lighten($key, $amount) {
  @return lighten(map-get($colors, $key), $amount);
}

@function color_darken($key, $amount) {
  @return darken(map-get($colors, $key), $amount);
}

@function to_opacity($color, $opacity) {
  @return rgba(color($color), $opacity);
}

// theme colors
$theme-colors: (
  "primary": $primary-bg,
  "secondary": $secondary-bg,
  "success": color("success"),
  "info": color("info"),
  "warning": color("warning"),
  "danger": color("danger"),
  "light": color("light"),
  "dark": color("dark"),
  "success-shade": color("success-shade"),
  "warning-shade": color("warning-shade"),
  "danger-shade": color("danger-shade"),
  "muted": color("muted"),
  "body": color("body"),
  "font": color("font"),
  "fontdark": color("fontdark"),
  "heading-text": $heading-text,
  // Form Control
  "formcontrol_normal_background": color("formcontrol_normal_background"),
  "formcontrol_normal_border": color("formcontrol_normal_border"),
  "formcontrol_focus_background": color("formcontrol_focus_background"),
  "formcontrol_focus_border": color("formcontrol_focus_border"),
  // Utilities
  "section_title_line": color("section_title_line"),
);

// text color
$utilities-text-colors: (
  "primary": $primary-text,
  "secondary": $secondary-text,
  "headings": $heading-text,
  "body": $body-text,
  "success": color("success-text"),
  "warning": color("warning-text"),
  "danger": color("danger-text"),
  "white": $white,
  "black": $black,
  "muted": color("muted"),
  "font": color("font"),
  "fontdark": color("fontdark"),
);
// Bootstrap customization
$font-family-sans-serif: $primary-font;
$body-bg: #ffffff;
$body-color: $body-text;
$headings-color: $heading-text;
$link-hover-color: $primary-text;
$link-color: $heading-text;

$line-height-base: 1.5 !default;
$line-height-sm: 1.25 !default;
$line-height-lg: 2 !default;

$font-size-base: 1rem !default;
$font-size-sm: $font-size-base * 0.875;
$font-size-lg: $font-size-base * 1.25;

$h1-font-size: 3rem;
$h2-font-size: 2.25rem;
$h3-font-size: 1.875rem;
$h4-font-size: 1.5rem;
$h5-font-size: 1.25rem;
$h6-font-size: 1.25rem;
$headings-font-weight: 600 !default;

// input color variables

$font-weight-base: 400;
$border-radius: 8px;

$input-bg: $white;

$input-color: $body-text;
$input-border-color: #94a3b8;
$input-border-width: 1px;

$input-border-radius: $border-radius;

$input-focus-bg: $input-bg;

$input-placeholder-color: #94a3b8;
$input-plaintext-color: $body-text;

$input-bg: $white;

$input-padding-y: 9px;
$input-padding-x: 12px;
$input-font-family: $primary-font;
$input-font-size: 16px;
$input-font-weight: 400;
$input-line-height: 1.5;
$modal-xl: 1200px;
$modal-sm:300px;
$modal-lg:800px;
$input-focus-border-color: #6366f1;
$modal-backdrop-bg: rgba(0, 0, 0, 10);

$input-btn-padding-y-sm: 0.5rem !default;
$input-btn-padding-x-sm: 0.75rem !default;
$input-btn-font-size-sm: $font-size-sm !default;
$input-btn-line-height-sm: $line-height-sm !default;

$modal-backdrop-bg: rgba(0, 0, 0, 10);
$alert-margin-bottom: 0rem;
$alert-border-radius: 0.375 rem;
$alert-border-width: 0px;

// Pagination

// scss-docs-start pagination-variables
$pagination-padding-y: 0.625rem !default;
$pagination-padding-x: 0.625rem !default;
$pagination-padding-y-sm: 0.25rem !default;
$pagination-padding-x-sm: 0.5rem !default;
$pagination-padding-y-lg: 0.75rem !default;
$pagination-padding-x-lg: 1.5rem !default;

$pagination-font-size: $font-size-base !default;

$pagination-color: $primary-text !default;
$pagination-bg: #edeff6 !default;
$pagination-border-radius: 3px !default;
$pagination-border-width: 0px !default;
$pagination-margin-start: 5px !default;
$pagination-margin-end: 5px !default;
$pagination-border-color: #edeff6 !default;

$pagination-focus-color: $white !default;
$pagination-focus-bg: $primary-bg !default;
$pagination-focus-box-shadow: unset !default;
$pagination-focus-outline: 0 !default;

$pagination-hover-color: $white !default;
$pagination-hover-bg: $primary-bg !default;
$pagination-hover-border-color: #edeff6 !default;

$pagination-active-color: $white !default;
$pagination-active-bg: $primary-bg !default;
$pagination-active-border-color: $primary-bg !default;

$pagination-disabled-color: rgb(100, 100, 100) !default;
$pagination-disabled-bg: #edeff6 !default;
$pagination-disabled-border-color: #edeff6 !default;

$pagination-transition: color 0.15s ease-in-out,
  background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
  box-shadow 0.15s ease-in-out !default;
// scss-docs-end pagination-variables
