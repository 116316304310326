:root {
  --primary: #043873;
  --secondary: #ffe492;
  --warning: #f93939;
  --gray-800: #ababab;
  --gray-700: #212121;
  --gray-600: #5b6471;
  --gray-500: #727d8d;
  --gray-300: #ababab;
  --gray-400: #8b94a2;
  --gray-100: #f2f5f8;

  --neutral-100: #d8dbdf;

  --response-bg: #f1f2f4;
  --cyan: #26b2cb;
  --drop-down-select-hover-bg: #f1f5f9;
  --drop-down-select-active-bg: #e0e7ff;
  --slider-bg: var(--secondary);

  --slider-handle-bg: #646464;
  --slider-connect-bg: #646464;
  --slider-tooltip-bg: var(--secondary);
  --slider-handle-ring-color: var(--gray-300);
  --slider-tooltip-color: var(--primary);
  --slider-tooltip-font-size: 0.75rem;
  --slider-tooltip-font-weight: 400;
  --slider-tooltip-radius: 4px;
  --slider-tooltip-py: 0px;

  --input-border-color: #94a3b8;
  --border-width: 1px;
  --black: #000000;
  --white: #ffffff;
}


