.dark-theme {
  --bs-link-color: #fff;
  --bs-link-hover-color: #fff;
  color: #ffffff;
  background-color: #363a45;

  .auth-left-panel-inner a {
    color: #ffffff !important;
  }
  .view-prompts:hover{
    background: #363A45;
    color:#ffffff;
  }
  .search-bar-input{
    background: #363A45;
    color:#ffffff;
  }
  
  ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #ffffff;
    opacity: 1; /* Firefox */
  }
  
  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #ffffff;
  }
  
  ::-ms-input-placeholder { /* Microsoft Edge */
    color: #ffffff;
  }
  
  
  .search-bar{
    background: #363A45;
  }
  
  .sub-heading-auth p {
    color: #ffffff;
  }
  
  .search-icon{
    color:#fff ;
  }
  .sub-heading-auth p {
    color: #ffffff;
  }

  .reva-sidebar,
  .auth-left-panel {
    background-color: #272b34;

    .sso-btn {
      color: #ffffff;
    }

    .account-text p {
      color: #ffffff !important;

      a {
        color: #ffffff !important;
      }
    }
  }

  .reva-sidebar-backdrop {
    background-color: rgb(0, 0, 0);
  }
  .new-placeholder::placeholder{
    color: #94A3B8 !important;
  }
  h1,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .chat-header h4,
  .examples-page h3 {
    color: #ffffff !important;
  }

  .chat-header {
    border-color: #8a8a8a !important;
    color: #ffffff !important;
    svg{
      color: #ffffff !important;
      path{
        stroke: #ffffff !important;
      }
    }
    button {
      background: transparent;
      color: #ffffff;
    }
  }

  .main-wrapper {
    color: #ffffff;

    .example-prompt {
      color: #ffffff !important;
      border-color: var(--gray-500) !important;

      h4,
      p {
        color: #ffffff;
      }
    }
  }

  .wmde-markdown {
    color: #ffffff !important;
  }
  .conversation-wrapper {
    height: calc(100% - 60px);
  
    .conversation {
      .conversation-inner{
        .avatar{
          .avatar-container{
            svg{
              path{
                fill: black !important;
              }
            }
          }
        }
        .logo{
          background-color: #ffffff !important;
          fill: #0000;
          font-weight: bold;
        }
      }
      
    }
  }
  .consent-container {
    Button{
      color: #ffffff!important;
    }
    consent-buttons {
      justify-content: center !important;
      @media sm {
        justify-content: end !important;
      }
    }
  }
  .response-bg {
    background-color: #44464b;
  }

  .feedback {
    .btn {
      color: #ffffff;
    }
  }

  .modal-content {
    background-color: #272B34;
    color: #ffffff;

    .btn-close {
      color: var(--text-gray-400);
    }
  }

  .form-control,
  .react-tel-input .form-control {
    background-color: transparent;
    border-color: var(--gray-400);
    color: var(--gray-400);
    
    &:disabled {
      background-color: transparent;
    }
    
  }
  .prompt-input-container{
    .prompt-input{
      .spinner{
        color: black !important;
      }
    }
  }
  .side-bar-view-prompt{
    color: #ffffff !important;
  }
  .form-check .form-check-label {
    color: #ffffff;
  }

  .form-label {
    color: var(--gray-600);
  }

  .accordion-button {
    color: #ffffff !important;

    &:hover,
    &:focus,
    &:active,
    &:not(.collapsed) {
      color: #ffffff !important;
    }

    &:after {
      background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
    }
  }

  .accordion-body {
    color: var(--gray-100) !important;
  }

  .faq-page .container-fluid>ul>li .example-prompts {
    color: #ffffff;
  }

  .PhoneInput {
    background: transparent;

    input {
      color: var(--gray-400);
    }

  }
  .validationBullets {
    .bullets-color {
      color: #ffffff !important;
    }
  }
  .container__feedbackType {
    .feedback-type {
      p {
        color: #ffffff;
      }

      svg {
        color: #ffffff;
      }
    }
  }

  .character-count {
    color: #ffffff !important;
  }

  .file-name {
    p {
      color: #0F172A !important;
    }
  }
  .container__main{
    .password-btn {
      background: transparent !important;
      color: #ffffff !important;
    }
    .side_bar{
      Button{
        color: #ffffff !important;
        svg{
          path{
            fill: rgb(255, 255, 255) !important;
          }
        }
      }
  }
}
}