@use "../../styles/variables" as *;
@use "../../styles/mixins" as *;

.container__feedbackType {
    margin-bottom: rem(24);

    .feedback-type {
        max-width: rem(510);
        background: transparent;
        border: 1px solid #949494;
        border-radius: rem(8);
        margin-bottom: rem(16);
        margin-top: rem(8);
        font-weight: 400;
        padding: rem(12);
        cursor: pointer;
        transition: all 0.2s ease-in-out;
        -o-transition: all 0.2s ease-in-out;
        -moz-transition: all 0.2s ease-in-out;
        -webkit-transition: all 0.2s ease-in-out;
        min-height: 100%;
        text-align: center;

        &:hover {
            -webkit-box-shadow: 0px 0px 9px 1px rgba(0, 0, 0, 0.2);
            -moz-box-shadow: 0px 0px 9px 1px rgba(0, 0, 0, 0.2);
            box-shadow: 0px 0px 9px 1px rgba(0, 0, 0, 0.2);
        }

        h4 {
            margin-top: rem(12);
            margin-bottom: rem(8);
            color: #2E3238;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
        }

        p {
            color: #2E3238;
            overflow: hidden;
            display: -webkit-box;
            // -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            font-weight: 400;
        }
    }
}