@import "../../styles/mixins.scss";

.faq-page {
  h3 {
    padding-top: 24px;
    padding-bottom: 12px;
  }

  .container-fluid>ul {
    list-style: none;
    padding: 0;
   // margin: 0 0 1.5rem 0px;
   // margin: 16px 0px;
    // display: flex;
    // flex-direction: column;
    // justify-content: space-between;
    // align-items: stretch;
    // gap: 12px;

    // @include lg {
    //   flex-direction: row;
    // }

    >li {
    //  flex: 1;
       margin: 6px 0px;
       padding: 0px 10px 12px 14px !important;
       
       @include md {
        padding: 0px 12px !important;
      }
      .example-prompts {

        color: #2e3238;
        background: transparent;
        text-align: left;
        border: 1px solid #727d8d;
        border-radius: 0.25rem;
        margin-bottom: 8px;
        font-weight: 400;
        padding: 12px 12px 3px 3px;
        
        h4{
          font-size: 16px !important;
        }
        cursor: pointer;
        transition: all 0.2s ease-in-out;
        -o-transition: all 0.2s ease-in-out;
        -moz-transition: all 0.2s ease-in-out;
        -webkit-transition: all 0.2s ease-in-out;
        min-height: 100%;

        &:hover,
        &.active {
          -webkit-box-shadow: 0px 0px 9px 1px rgba(0, 0, 0, 0.2);
          -moz-box-shadow: 0px 0px 9px 1px rgba(0, 0, 0, 0.2);
          box-shadow: 0px 0px 9px 1px rgba(0, 0, 0, 0.2);
        }

        h4 {
          margin-top: 12px;
          margin-bottom: 8px;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }

        p {
          overflow: hidden;
          display: -webkit-box;
          // -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          font-weight: 400;
        }
      }
    }
  }
}

// .conversation-wrapper {
//   height: calc(100% - 60px);

//   .conversation {
//     padding: 24px 12px;

//     .conversation-inner {
//       display: flex;
//       flex-direction: row;
//       margin: auto;
//       max-width: 720px;
//     }

//     @include md {
//       padding: 24px 32px;
//     }

//     .avatar {
//       width: 38px;
//       height: 38px;
//       border-radius: 6px;
//       margin-right: 24px;
//       background: #727d8d;
//       text-align: center;
//       line-height: 38px;
//       color: #fff;
//       font-size: large;
//       min-width: 38px;
//     }

//     .message {
//       flex-grow: 1;
//       max-width: 560px;
//     }

//     .feedback {
//       width: 40px;
//       display: flex;
//       //justify-content: space-between;
//       align-items: center;
//       margin-left: 12px;
//     }
//   }
// }

.wmde-markdown {
  ul {
    li {
      list-style: disc;
    }
  }
}