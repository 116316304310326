@import "../../styles/mixins";

.auth-left-panel {
  width: 100%;
  padding-top: rem(40);
  padding-bottom: rem(40);
  height: 100%;
  position: relative;
  z-index: 1;
  @include md {
    width: rem(400);
  }
  @include lg {
    width: rem(540);
    padding-top: rem(80);
    padding-bottom: rem(80);
  }
  .scroll-bar {
    overflow-x: hidden;
    overflow-y: scroll;
    @include md {
      height: unset;
    }
  }
  .auth-left-panel-inner {
    padding-left: rem(30);
    padding-right: rem(30);
    @include lg {
      padding-left: rem(50);
      padding-right: rem(50);
    }
  }
}
.auth-right-panel {
  // background: linear-gradient(38.74deg, #139ebc 30.2%, #aa1ecd 97.39%);
  // -moz-background: linear-gradient(38.74deg, #139ebc 30.2%, #aa1ecd 97.39%);
  // -webkit-background: linear-gradient(38.74deg, #139ebc 30.2%, #aa1ecd 97.39%);
  .img-fluid{
    max-width: 70%;
  }
  background-color: black ;
  .auth-right-panel-inner {
    text-align: center;
    img {
      width: 214px;
      @include lg {
        width: unset;
      }
    }
    h1 {
      font-size: 24px;
      line-height: 32px;
      @include lg {
        font-size: 48px;
        line-height: 48px;
      }
    }
  }
}

// .left-frame {
//   background-image: url(../../assets/svg/rectangle.svg);
//   background-repeat: space;
//   position: absolute;
//   width: 154px;
//   left: -80px;
//   top: -80px;
//   height: 110%;

//   z-index: 0;
// }
// .right-frame {
//   background-image: url(../../assets/svg/rectangle.svg);
//   background-repeat: space;
//   position: absolute;
//   width: 150px;
//   right: -80px;
//   top: 0px;
//   height: 100%;

//   z-index: 0;
// }
