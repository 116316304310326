.validationBullets {
  list-style-type: disc;
  margin-left: 20px;
  width: 90% !important;
  li {
    list-style: disc;
    font-size: 0.875em;
    color: gray;
  }
}
