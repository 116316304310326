@use "../../styles/mixins" as *;
.accordion-item {
  background-color: transparent !important;
  border: 0px none !important;
}
.accordion {
  padding-left: 4px !important;
  padding-right: 8px !important;
  @include sm {
    padding-left: 24px !important;
    padding-right: 24px !important;
}
}


.accordion,
.accordion-item {
  border: none;
}
.accordion-item.active {
  border: none;
  background-color: transparent;
}

.accordion-button {
  font-weight: 600;
  font-size: 18px;
  @include sm {
    font-size: 20px;
}
  background-color: transparent !important;
  position: relative;
  &:not(.collapsed) {
    color: #000000 !important;
    background-color: transparent !important;
    box-shadow: none !important;
    padding-bottom: 8px !important;
  }
  &:after {
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%2300000'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
  }
  &:focus {
    z-index: 3;
    border-color: unset !important;
    outline: 0;
    box-shadow: unset !important;
  }
  &:link,
  &:visited,
  &:active {
    background-color: transparent !important;
    color: #ffffff !important;
    text-decoration: none !important;
    border: hidden !important;
    border-color: #fff !important;
    box-shadow: 0px !important;
  }
}
.accordion-body {
  padding-top: 8px;
}
