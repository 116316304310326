@import "../../styles/mixins";
.reva-sidebar {
  background-color: var(--neutral-100);
  width: rem(280);
  padding: 34px 20px 24px;
  z-index: 11;
  transition: transform 0.3s ease-in-out;
  transform: translateX(-100%);
  z-index: 111;
  position: fixed;
  top: 0;
  left: 0;
  .sidebar-search-bar{
    position: relative;
    input{
      padding-left: 8px !important;
      padding-right: 40px !important;
    }
    .search-icon{
      position: absolute;
      right: 0 !important;
      top: 10px !important;
    }
  }
  .saved-chats-list {
    padding-right: rem(24);
    margin-right: rem(-20);
    .btn {
      height: unset;
    }
    .chat-history {
      cursor: pointer;
    }
  }
  @include md {
    transform: unset !important;
  }
}
.reva-sidebar-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 110;
  width: 100vw;
  height: 100vh;
  background-color: rgb(0, 0, 0);
  opacity: 0.5;
  @include md {
    display: none !important;
  }
}
.saved-chat-name {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.side-bar-view-prompt {
  color: #043873;
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
  display: none;
}

@media (max-width: 768px) {
  .side-bar-view-prompt {
    display: block;
  }
}
