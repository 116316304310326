@use "../../styles/variables" as *;
@use "../../styles/mixins" as *;

.container__main {
    margin-top: rem(22);
    .side_bar{
        margin-right: 20px;
        Button{
           font-size: 14px;
           padding: 8px 8px;
           margin-bottom: 16px;
           min-width: 168px;
           text-align: left;
           svg{
            path{
              fill: black !important;
            }
          }
           &.active{
            background-color:#043873 ;
            color: #fff;
            svg{
                path{
                  fill: white !important;
                }
              }
           }
           &:hover{
            background: #043873 !important;
            color:#fff;
            svg{
                path{
                  fill: white !important;
                }
              }
           }
        }
    }
    
    .consent-containers{
        height: rem(270) !important;
        overflow-y: auto;
        margin-bottom: rem(68);
        @include sm {
            height: rem(296) !important;
        }
        Button{
            margin: 0;
            position: absolute;
            left: 50%;
            bottom: 0;
            transform: translate(-50%, -50%);
        }
        .form-check {
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 0px 20px;
            .form-check-input{
                border-radius: 14px !important;
                height: 28px;
                width: 53px;
            }
        }
        
    }
}
.consent-container {
    .consent-buttons {
      justify-content: center !important;
      @include sm {
        justify-content: end !important;
      }
      Button {
        padding : 0px 12px;
        @include sm {
            padding: 0px 40px;
        }
      }
    }
  }
.phone-input {
    &.is-invalid {
        border-color: $danger-inputBorderColor !important;
    }
}

.react-tel-input {
    .form-control {
        width: 100% !important;
        height: rem(44) !important;
        border: 1px solid #94a3b8 !important;
        padding-left: rem(110) !important;
    }

    .flag-dropdown {
        background-color: unset !important;
        border: unset !important;
        width: rem(90) !important;
        border-right: 1px solid #94a3b8 !important;

        .selected-flag {
            padding-left: rem(32);
            width: 100% !important;

            .arrow {
                margin-top: rem(-3);
                border-left: none !important;
                border-right: none !important;
                border-top: none !important;
                margin-left: 10px;
                width: 100%;
                height: 100%;
                background-image: url("../../assets/svg/phoneArrow.svg");
                background-repeat: no-repeat;
            }

            .up {
                border-top: none !important;
                border-bottom: none !important;
                -webkit-transform: scaleY(-1);
                transform: scaleY(-1);
                top: 0% !important;
            }
        }
    }
}

.react-tel-input .flag-dropdown.open .selected-flag {
    background-color: transparent !important;
    width: 100% !important;
}

.react-tel-input .selected-flag:hover,
.react-tel-input .selected-flag:focus {
    background-color: transparent !important;
}

.PhoneInput {
    width: 100%;
    font-family: "Inter", sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212121;
    background-color: #ffffff;
    background-clip: padding-box;
    border: 1px solid #94a3b8;
    appearance: none;
    border-radius: 8px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;


    .PhoneInputCountry {
        padding: 9px 12px;
    }


    input {
        padding: 9px 12px;
        border: none;
        border-left: 1px solid #94a3b8;
        background-color: transparent;

        &:focus-visible {
            outline: none;
        }
    }
}

.PhoneInput--focus {

    input {
        border: none;
        border-left: 1px solid #94a3b8;

        &:focus-visible {
            outline: unset;
        }
    }
}