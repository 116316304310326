@use "../../styles/variables" as *;
@use "../../styles/mixins" as *;


.site-feedback-button {
    position: fixed;
    top: 50%;
    right: -31px;

    z-index: 2;

    background: transparent;
    cursor: pointer;

    transition: all 0.3s ease-in-out;
    transform: rotate(270deg);
    color: $white;
    background-color: $primary-bg;
    border-radius: rem(8) rem(8) 0px 0px;
    padding: rem(10) rem(16);
    font-size: 12px;
    font-weight: 600;


    border: 1px solid transparent;

    @include md {
        p{
            font-size: 1rem;
        }
    }
}