@use "../../styles/variables" as *;
@use "../../styles/mixins" as *;



.attachments {
    input[type='file'] {
        display: none;
        max-width: rem(130);
    }

    .file-name {
        max-width: fit-content;
        padding: rem(8) rem(12) rem(8) rem(12);
        background: #F1F5F9;
        border-radius: rem(26);
        text-align: center;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        overflow: hidden;
        p{  
            overflow: hidden;
        }
        svg {
            color: #334155 !important;
            cursor: pointer;
        }
    }

    .container__attachments {
        max-width: rem(130);
        background-color: #F1F2F4;
        padding: rem(6) rem(12);



        span {
            margin-left: rem(8);
            color: #0F172A;
        }

        svg {
            color: #0F172A;
        }

    }

    label {
        max-width: fit-content;
        cursor: pointer;

    }

    .attachment-info {
        color: #949494;
        font-weight: 400 !important;
        font-size: rem(12);

    }
}